import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Jacob M. Foley"
          subTitle="Leading Devops + Infrastructure with simple solutions for complex problems.  "
        />
        {/*<BasicTextModule*/}
        {/*  title="A super-fast theme that is easy to get started, using the power of*/}
        {/*    GatsbyJS"*/}
        {/*  content="Using modern CSS properties such as grid, this theme is optmised for*/}
        {/*    speed and mobile devices. Giving users an excellent experience on*/}
        {/*    any device. Future-proofing your product."*/}
        {/*  link="/products"*/}
        {/*  linkText="View Products"*/}
        {/*/>*/}
        {/*<PerksModule>*/}
        {/*  <Perk title="The Title" content="The content" />*/}
        {/*</PerksModule>*/}
        {/*<Features*/}
        {/*  title="Featured Products from Barcadia."*/}
        {/*  introduction="Vivamus quam mauris, pulvinar vel mauris id, interdum semper neque. Proin malesuada libero eget tellus scelerisque, id egestas tortor egestas."*/}
        {/*/>*/}
        {/*<LatestPosts*/}
        {/*  title="The Latest from Barcadia"*/}
        {/*  introduction="Cras scelerisque, tellus sed gravida tincidunt, velit tellus blandit justo, nec viverra augue erat in erat. Maecenas iaculis sed purus non fringilla."*/}
        {/*/>*/}
      </Layout>
    </>
  )
}

export default Index
